<template>
  <DateTooltip
    :date="date"
    :div-css="divCss"
    :starting-text="startingText"
    :ending-text="endingText"
  >
    <button
      class="tw-cursor-pointer tw-text-xs tw-text-dusk-500 tw-underline dark:tw-text-dusk-200"
      @click.stop="undoEdit"
    >
      {{ undoEditLabel }}
    </button>
  </DateTooltip>
</template>
<script>
/* eslint-disable vue/component-api-style */
import DateTooltip from '@/src/common/components/DateTooltip.vue';
import { differenceInSeconds } from 'date-fns';

export default {
  components: {
    DateTooltip,
  },

  props: {
    date: {
      type: String,
      default: '',
    },
    divCss: {
      type: String,
      default: '',
    },
    startingText: {
      type: String,
      default: '',
    },
    endingText: {
      type: String,
      default: '',
    },
    undoLengthSeconds: {
      type: Number,
      default: 0,
    },
  },

  emits: ['undoEdit'],

  data() {
    return {
      undoCountdown: '',
    };
  },

  computed: {
    undoEditLabel() {
      if (this.undoCountdown) {
        return `Undo edit - ${this.undoCountdown}`;
      }
      return '';
    },
  },

  watch: {
    date() {
      this.updateUndoCountdown();
    },
  },

  mounted() {
    this.updateUndoCountdown();
  },

  methods: {
    undoEdit() {
      this.$emit('undoEdit');
    },

    updateUndoCountdown() {
      const editedDate = new Date(this.date);
      const now = new Date();
      const diffInSeconds = differenceInSeconds(now, editedDate);
      const secondsUntilUndoDisabled = Math.ceil(this.undoLengthSeconds - diffInSeconds);
      if (secondsUntilUndoDisabled > 0) {
        this.undoCountdown = `${secondsUntilUndoDisabled}s`;
        setTimeout(this.updateUndoCountdown.bind(this), 1000);
      } else {
        this.undoCountdown = '';
      }
    },
  },
};
</script>
