const validateEmail = (email) => {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(email);
};

const phoneNumberRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/;
const phoneNumberErrorMessage = 'Please enter a valid phone number.';

function isValidPhoneNumber(value) {
  if (!value) return true; // Allow empty values
  return phoneNumberRegex.test(value);
}

export { validateEmail, isValidPhoneNumber, phoneNumberErrorMessage };
