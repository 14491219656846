<template>
  <div>
    <input
      v-show="false"
      ref="fileInput"
      type="file"
      @change="uploadAttachment"
    />
    <v-tooltip
      location="top"
      class="tw-z-60"
    >
      <template #activator="{ props }">
        <div class="tw-float-left">
          <v-btn
            icon
            aria-label="Add Attachment"
            v-bind="props"
            @click.prevent="$refs.fileInput.click()"
          >
            <v-icon size="small"> mdi-paperclip </v-icon>
          </v-btn>
        </div>
      </template>
      <span>Add Attachment</span>
    </v-tooltip>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const currentTicket = computed(() => store.state.currentTicket);
const organizationId = computed(() => currentTicket.value?.organizationId);
const fileInput = ref(null);

const emit = defineEmits(['uploadAttachment']);

const uploadAttachment = (event) => {
  emit('uploadAttachment', event, organizationId.value);
  fileInput.value.value = '';
};
</script>
