import axios from 'axios';
import {
  deserializeJsonApi,
  handleCsrfToken,
  serializeJsonApi,
  toCamelCase,
} from '@/src/util/service-mixins.js';

export default class KnowledgeBaseService {
  constructor() {
    Object.assign(this, handleCsrfToken);
    this.assignCsrfToken();
  }

  async getArticles(options) {
    const { filter, pageNumber, articlesPerPage, searchText, order } = options;
    const requestUrl = `/api/knowledge_base_articles`;

    try {
      const response = await axios.get(requestUrl, {
        params: {
          query: searchText,
          filter: filter,
          order: order,
          'page[number]': pageNumber,
          'page[size]': articlesPerPage,
        },
      });

      return deserializeJsonApi(response);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  async getArticle(id) {
    // Uncomment to mock a 403 forbidden error status
    // throw { status: 403 };

    // Uncomment to mock a 404 not found error status
    // throw { status: 404 };

    const requestUrl = `/api/knowledge_base_articles/${id}`;

    try {
      const response = await axios.get(requestUrl);
      return deserializeJsonApi(response);
    } catch (error) {
      throw toCamelCase({
        data: error.response.data,
        status: error.response.status,
      });
    }
  }

  async createArticle(attributes) {
    const requestUrl = `/api/knowledge_base_articles`;

    try {
      const jsonApiObject = serializeJsonApi('knowledge_base_articles', attributes);
      const response = await axios.post(requestUrl, jsonApiObject);
      return deserializeJsonApi(response);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  async deleteArticle(articleId) {
    const requestUrl = `/api/knowledge_base_articles/${articleId}`;

    try {
      const response = await axios.delete(requestUrl);
      return toCamelCase(response.data);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  async updateArticle(articleId, attributes) {
    const requestUrl = `/api/knowledge_base_articles/${articleId}`;

    try {
      const jsonApiObject = serializeJsonApi('knowledge_base_articles', attributes);
      const response = await axios.put(requestUrl, jsonApiObject);
      return deserializeJsonApi(response);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  async deleteAttachment(stepId) {
    // If stepId is not a number, then we're trying to delete a brand new step
    // that hasn't been saved to the database yet, so we don't need to make a request.
    // You can tell that it's a brand new step because the stepId is a UUID
    if (!(typeof stepId === 'string' && /^\d+$/.test(stepId)) && !Number.isInteger(stepId)) return;
    const requestUrl = `/api/knowledge_base_steps/${stepId}/purge_attachment`;

    try {
      const response = await axios.delete(requestUrl);
      return toCamelCase(response.data);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  async createReference(articleId, attributes) {
    const requestUrl = `/api/knowledge_base_references`;

    attributes['relationships'] = {
      knowledgeBaseArticle: {
        data: { type: 'knowledge_base_articles', id: articleId },
      },
    };

    try {
      const jsonApiObject = serializeJsonApi('knowledge_base_references', attributes);
      const response = await axios.post(requestUrl, jsonApiObject);
      return deserializeJsonApi(response);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  async updateReference(referenceId, attributes) {
    const requestUrl = `/api/knowledge_base_references/${referenceId}`;

    try {
      const jsonApiObject = serializeJsonApi('knowledge_base_references', attributes);
      const response = await axios.put(requestUrl, jsonApiObject);
      return deserializeJsonApi(response);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  async createStep(articleId, attributes) {
    const requestUrl = `/api/knowledge_base_steps`;

    attributes['relationships'] = {
      knowledgeBaseArticle: {
        data: { type: 'knowledge_base_articles', id: articleId },
      },
    };

    try {
      const jsonApiObject = serializeJsonApi('knowledge_base_steps', attributes);
      const response = await axios.post(requestUrl, jsonApiObject);
      return deserializeJsonApi(response);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  async updateStep(stepId, attributes) {
    const requestUrl = `/api/knowledge_base_steps/${stepId}`;

    try {
      const jsonApiObject = serializeJsonApi('knowledge_base_steps', attributes);
      const response = await axios.put(requestUrl, jsonApiObject);
      return deserializeJsonApi(response);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  async deleteStep(stepId) {
    const requestUrl = `/api/knowledge_base_steps/${stepId}`;

    try {
      const response = await axios.delete(requestUrl);
      return toCamelCase(response.data);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }
}
