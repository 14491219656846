<template>
  <div class="tw-flex tw-max-h-40 tw-flex-wrap tw-overflow-auto">
    <div
      v-for="(attachment, index) in pendingAttachments"
      :key="attachment.filename"
      class="tw-mb-1 tw-mr-1 tw-h-fit tw-max-w-76 tw-flex-none tw-bg-earl-40 tw-px-3 tw-py-2 dark:tw-bg-earl-600 dark:tw-text-earl-50"
    >
      <div class="tw-flex tw-items-center">
        <v-progress-circular
          v-if="attachment.uploading"
          :size="18"
          :width="2"
          indeterminate
          class="tw-mr-2"
        />
        <v-icon
          v-else
          color="earl-700"
          class="tw-mr-1"
          >mdi-check</v-icon
        >
        <v-tooltip>
          <template #activator="{ props }">
            <div v-bind="props">
              <ExpandableImage
                v-if="isSupportedImage(attachment.contentType) && attachment.downloadUrl"
                :src="attachment.downloadUrl"
                :alt="attachment.filename"
                max-height="100px"
                max-width="100px"
                contain
              >
                <slot></slot>
              </ExpandableImage>
              <div v-else>
                <span>
                  {{ truncateFilename(attachment.filename, 14) }}
                </span>
              </div>
            </div>
          </template>
          <span>{{ attachment.filename }}</span>
        </v-tooltip>

        <v-btn
          class="tw-float-right tw-ml-2 tw-mt-0.8 tw-w-4 tw-p-0"
          height="1.5rem"
          icon="mdi-close"
          @click="removeCommentAttachment(index)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAttachments } from '@/src/tickets/composables/useAttachments.js';
import ExpandableImage from '@/src/common/components/ExpandableImage.vue';

defineProps({
  pendingAttachments: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['attachment-removed']);

const { truncateFilename } = useAttachments();

const isSupportedImage = (contentType) => {
  return ['image/jpeg', 'image/png', 'image/gif'].includes(contentType);
};

const removeCommentAttachment = (index) => {
  emit('attachment-removed', index);
};
</script>
