import axios from 'axios';
import { handleCsrfToken, toCamelCase, toSnakeCase } from '@/src/util/service-mixins.js';

export default class TicketsService {
  constructor() {
    Object.assign(this, handleCsrfToken);
    this.assignCsrfToken();
  }

  /**
   * Supports Ticket Views
   * @param {Object} params - page, filter and search query parameters
   */
  async getTicketsList(params = {}) {
    let viewParam, sortParam, pageParam, searchParam, filtersParam;

    if (params.view) viewParam = `view=${params.view}`;
    if (params.sort) sortParam = `sort=${params.sort}`;
    if (params.q) searchParam = `q=${params.q}`;
    if (params.page)
      pageParam = `page[number]=${params.page.number}&page[size]=${params.page.size}`;
    if (params.filter) {
      filtersParam = Object.keys(params.filter)
        .map((key) => {
          const filterEntry = Object.entries(params.filter[key])[0];
          return `filter[${key}][${filterEntry[0]}]=${filterEntry[1]}`;
        })
        .join('&')
        .trim();
    }

    const queryParams = [viewParam, sortParam, pageParam, filtersParam, searchParam]
      .filter(Boolean) // Filter out undefined params
      .join('&')
      .trim();

    const requestUrl = `/api/main/tickets?${queryParams}`;

    try {
      const { data } = await axios.get(requestUrl);
      return toCamelCase(data);
    } catch (error) {
      throw 'An error occurred when retrieving tickets.';
    }
  }

  /**
   * Support Ticket Filters
   * @param {Object} params - page, filter and search query parameters
   */
  // TODO: add unit tests for this whenever it gets consumed
  /* istanbul ignore next */
  async getTicketsByFilter(params = {}) {
    let viewParam, sortParam, pageParam, searchParam, filtersParam;

    const includeParam = 'include=tickets';
    if (params.view) viewParam = `view=${params.view}`;
    if (params.sort) sortParam = `sort=${params.sort}`;
    if (params.q) searchParam = `q=${params.q}`;
    if (params.page)
      pageParam = `page[number]=${params.page.number}&page[size]=${params.page.size}`;
    if (params.filter) {
      filtersParam = Object.keys(params.filter)
        .map((key) => {
          const filterEntry = Object.entries(params.filter[key])[0];
          return `filter[${key}][${filterEntry[0]}]=${filterEntry[1]}`;
        })
        .join('&')
        .trim();
    }

    const queryParams = [viewParam, sortParam, pageParam, filtersParam, searchParam, includeParam]
      .filter(Boolean) // Filter out undefined params
      .join('&')
      .trim();

    const requestUrl = `/api/main/ticket_filters/${params.id}?${queryParams}`;

    try {
      const { data } = await axios.get(requestUrl);
      return toCamelCase(data);
    } catch (error) {
      throw 'An error occurred when retrieving tickets.';
    }
  }

  /**
   * @param {Object} ticket - object containing ticket data
   */
  async updateTicket(ticket) {
    const requestUrl = `/api/main/tickets/${ticket.id}`;

    try {
      const { data } = await axios.put(requestUrl, toSnakeCase({ ticket }));
      return toCamelCase(data);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  /**
   * @param {Object} ticket - object containing ticket data
   */
  async createTicket(ticket) {
    const requestUrl = '/api/main/tickets';

    try {
      const { data } = await axios.post(requestUrl, toSnakeCase({ ticket }));
      return toCamelCase(data);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  /**
   * Archives a ticket (soft-delete)
   */
  async deleteTicket(ticketId) {
    const requestUrl = `/api/main/tickets/${ticketId}`;

    try {
      await axios.delete(requestUrl);
    } catch (_e) {
      throw 'An error occurred when deleting ticket.';
    }
  }

  async getTicketViews() {
    const requestUrl = '/api/ticket_views';
    try {
      const { data } = await axios.get(requestUrl);
      return data.ticket_views;
    } catch (error) {
      throw 'An error occurred when retrieving ticket views.';
    }
  }

  /**
   * @param {String} ticketNumber - ticket number for ticket details retrieval
   */
  async getTicketDetails(ticketNumber, signal) {
    const requestUrl = `/api/main/tickets/${ticketNumber}`;

    return axios.get(requestUrl, { signal }).then(({ data }) => {
      return toCamelCase(data);
    });
  }

  /**
   * @param {String} ticketNumber - ticket number for ticket details retrieval
   */
  async getActivities(ticketNumber, signal) {
    const requestUrl = `/api/main/tickets/${ticketNumber}/activities`;

    return axios
      .get(requestUrl, { signal })
      .then(({ data }) => {
        return toCamelCase(data);
      })
      .catch(() => {
        throw 'An error occurred when retrieving activities.';
      });
  }

  /**
   * @param {String} ticketNumber - ticket number for ticket details retrieval
   */
  async getAlerts(ticketNumber, signal) {
    const requestUrl = `/api/main/tickets/${ticketNumber}/alerts`;

    return axios
      .get(requestUrl, { signal })
      .then(({ data }) => {
        return toCamelCase(data);
      })
      .catch(() => {
        throw 'An error occurred when retrieving alerts.';
      });
  }

  /**
   * @param {String} ticketNumber - ticket number for ticket details retrieval
   */
  async getTicketComments(ticketNumber, signal) {
    const requestUrl = `/api/main/tickets/${ticketNumber}/ticket_comments`;

    return axios
      .get(requestUrl, { signal })
      .then(({ data }) => {
        return toCamelCase(data);
      })
      .catch(() => {
        throw 'An error occurred when retrieving ticket comments.';
      });
  }

  /**
   * @param {String} ticketId
   * @param {Object} payload - object containing ticket comment/note data
   */
  async submitTicketComment(ticketId, payload) {
    const requestUrl = `/api/tickets/${ticketId}/comments`;

    try {
      const { data } = await axios.post(requestUrl, toSnakeCase(payload));
      return toCamelCase(data);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  /**
   * @param {Object} ticket - object containing ticket data
   */
  async updateTicketComment(ticketId, ticketComment) {
    const requestUrl = `/api/main/tickets/${ticketId}/ticket_comments/${ticketComment.id}`;

    try {
      const { data } = await axios.put(requestUrl, toSnakeCase({ ticketComment }));
      return toCamelCase(data);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  /**
   * Soft Deletes a TicketComment (sets deleted to true)
   */
  async softDeleteTicketComment(ticketId, ticketCommentId) {
    const requestUrl = `/api/main/tickets/${ticketId}/ticket_comments/${ticketCommentId}/soft_delete`;

    try {
      const { data } = await axios.patch(requestUrl);
      return toCamelCase(data);
    } catch (_e) {
      throw 'An error occurred when deleting comment.';
    }
  }

  /**
   * @param {String} ticketId
   * @param {String} ticketCommentId
   */
  async undoEditTicketComment(ticketId, ticketComment) {
    const requestUrl = `/api/main/tickets/${ticketId}/ticket_comments/${ticketComment.id}`;

    ticketComment.previousBody = null;

    try {
      const { data } = await axios.put(requestUrl, toSnakeCase({ ticketComment }));
      return toCamelCase(data);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  /**
   * @param {String} lastTimestamp
   */
  async livePoll(lastTimestamp) {
    const requestUrl = `/api/live_reload?last_fetch_at=${lastTimestamp}`;

    try {
      const { data } = await axios.get(requestUrl);
      return toCamelCase(data);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  /**
   * @param {object} labor
   */
  async addTimeSpent(labor) {
    const requestUrl = `/api/tickets/${labor.ticketId}/labor`;

    try {
      const { data } = await axios.post(requestUrl, toSnakeCase({ labor }));
      return toCamelCase(data);
    } catch (error) {
      throw toCamelCase(error.response.data);
    }
  }

  async createTicketTask(task) {
    const requestUrl = `/api/ticket_tasks`;

    try {
      const { data } = await axios.post(requestUrl, toSnakeCase({ task }));
      return toCamelCase(data);
    } catch (_e) {
      throw 'Failed to add task. Please try again.';
    }
  }

  async updateTicketTask(taskId, task) {
    const requestUrl = `/api/ticket_tasks/${taskId}`;

    try {
      const { data } = await axios.put(requestUrl, toSnakeCase({ task }));
      return toCamelCase(data);
    } catch (_e) {
      throw 'Failed to update task. Please try again.';
    }
  }

  async deleteTicketTask(taskId) {
    const requestUrl = `/api/ticket_tasks/${taskId}`;

    try {
      await axios.delete(requestUrl);
    } catch (_e) {
      throw 'Failed to delete task. Please try again.';
    }
  }

  async mergeTickets(ticketNumber, masterTicketId) {
    const requestUrl = `/api/tickets/${ticketNumber}/merge`;

    try {
      await axios.post(requestUrl, { master_ticket_id: masterTicketId });
    } catch (_e) {
      throw 'Failed to merge ticket. Please try again.';
    }
  }
}
