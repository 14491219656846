<template>
  <v-tooltip
    v-if="date"
    z-index="50"
    class="print:tw-hidden"
  >
    <template #activator="{ props: activatorProps }">
      <div
        v-bind="activatorProps"
        :class="divCss"
      >
        <span
          class="tw-mx-2 tw-text-xs tw-text-earl-600 dark:tw-text-earl-200"
          :aria-label="dateAriaLabel"
          >{{ spanTextWithFormattedDate }}</span
        >
        <slot></slot>
      </div>
    </template>
    <span>{{ formattedDateLongWithHoursAndMinutes }}</span>
  </v-tooltip>
</template>

<script setup>
import { computed } from 'vue';
import { useMobile } from '@/src/common/composables/useMobile';
import useFormatDate from '@/src/common/composables/useFormatDate.js';

const props = defineProps({
  date: {
    type: String,
    default: '',
  },
  includeHoursAndMinutes: {
    type: Boolean,
    default: true,
  },
  divCss: {
    type: String,
    default: '',
  },
  startingText: {
    type: String,
    default: '',
  },
  endingText: {
    type: String,
    default: '',
  },
});

const { isMobile } = useMobile();

const {
  formatDateTimeRelativeWithHoursAndMinutes,
  formatDateTimeRelativeOmitSameYear,
  formatDateTimeLongWithHoursAndMinutes,
} = useFormatDate();

const spanTextWithFormattedDate = computed(() => {
  const formattedDate =
    props.includeHoursAndMinutes && !isMobile.value
      ? formatDateTimeRelativeWithHoursAndMinutes(props.date)
      : formatDateTimeRelativeOmitSameYear(props.date);

  return props.startingText + formattedDate + props.endingText;
});

const formattedDateLongWithHoursAndMinutes = computed(() =>
  formatDateTimeLongWithHoursAndMinutes(props.date)
);

const dateAriaLabel = computed(
  () => `${spanTextWithFormattedDate.value}, ${formattedDateLongWithHoursAndMinutes.value}`
);
</script>
