import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  formatDateToLongFormat,
  formatDateToUserDisplayFormat,
  formatDateToRelativeUserDisplayFormat,
  parseDate,
  parseDateTime,
} from '@/src/common/helpers/date-helpers.js';

const defaultDateDisplayFormat = 'MMM DD, YYYY';

export default function useFormatDate() {
  const store = useStore();
  const currentUser = computed(() => store.getters.currentUser);

  const formatDate = (date) => {
    return formatDateToUserDisplayFormat(
      parseDate(date),
      currentUser.value?.selectedDateDisplayFormat || defaultDateDisplayFormat
    );
  };

  const formatDateToUTC = (date) => {
    date = parseDateTime(date);
    const utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    return formatDateToUserDisplayFormat(
      utcDate,
      currentUser.value?.selectedDateDisplayFormat || defaultDateDisplayFormat
    );
  };

  const formatDateRelative = (date) => {
    return formatDateToRelativeUserDisplayFormat(parseDate(date), {
      userDateDisplayFormat:
        currentUser.value?.selectedDateDisplayFormat || defaultDateDisplayFormat,
      wantsRelativeDates: currentUser.value?.wantsRelativeDates,
    });
  };

  const formatDateRelativeOmitSameYear = (date) => {
    return formatDateToRelativeUserDisplayFormat(parseDate(date), {
      userDateDisplayFormat:
        currentUser.value?.selectedDateDisplayFormat || defaultDateDisplayFormat,
      omitSameYear: true,
      wantsRelativeDates: currentUser.value?.wantsRelativeDates,
    });
  };

  const formatDateLong = (date) => {
    return formatDateToLongFormat(parseDate(date));
  };

  const formatDateLongWithHoursAndMinutes = (date) => {
    return formatDateToLongFormat(parseDate(date), { includeHoursAndMinutes: true });
  };

  const formatDateTime = (dateTime) => {
    return formatDateToUserDisplayFormat(
      parseDateTime(dateTime),
      currentUser.value?.selectedDateDisplayFormat || defaultDateDisplayFormat
    );
  };

  const formatDateTimeRelative = (dateTime) => {
    return formatDateToRelativeUserDisplayFormat(parseDateTime(dateTime), {
      userDateDisplayFormat:
        currentUser.value?.selectedDateDisplayFormat || defaultDateDisplayFormat,
      wantsRelativeDates: currentUser.value?.wantsRelativeDates,
    });
  };

  const formatDateTimeRelativeOmitSameYear = (dateTime) => {
    return formatDateToRelativeUserDisplayFormat(parseDateTime(dateTime), {
      userDateDisplayFormat:
        currentUser.value?.selectedDateDisplayFormat || defaultDateDisplayFormat,
      omitSameYear: true,
      wantsRelativeDates: currentUser.value?.wantsRelativeDates,
    });
  };

  const formatDateTimeRelativeWithHoursAndMinutes = (dateTime) => {
    return formatDateToRelativeUserDisplayFormat(parseDateTime(dateTime), {
      userDateDisplayFormat:
        currentUser.value?.selectedDateDisplayFormat || defaultDateDisplayFormat,
      wantsRelativeDates: currentUser.value?.wantsRelativeDates,
      includeHoursAndMinutes: true,
    });
  };

  const formatDateTimeLong = (dateTime) => {
    return formatDateToLongFormat(parseDateTime(dateTime));
  };

  const formatDateTimeLongWithHoursAndMinutes = (dateTime) => {
    return formatDateToLongFormat(parseDateTime(dateTime), { includeHoursAndMinutes: true });
  };

  const formatDateTimeToUserDisplayFormatWithHoursAndMinutes = (dateTime) => {
    const userDateDisplayFormatWithTime =
      (currentUser.value?.selectedDateDisplayFormat || defaultDateDisplayFormat) + ', h:mm a';
    return formatDateToUserDisplayFormat(parseDateTime(dateTime), userDateDisplayFormatWithTime);
  };

  return {
    formatDate,
    formatDateToUTC,
    formatDateRelative,
    formatDateRelativeOmitSameYear,
    formatDateLong,
    formatDateLongWithHoursAndMinutes,
    formatDateTime,
    formatDateTimeRelative,
    formatDateTimeRelativeOmitSameYear,
    formatDateTimeRelativeWithHoursAndMinutes,
    formatDateTimeLong,
    formatDateTimeLongWithHoursAndMinutes,
    formatDateTimeToUserDisplayFormatWithHoursAndMinutes,
  };
}
