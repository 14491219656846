import { ref, onMounted, onUnmounted, nextTick } from 'vue';

export function useTruncation() {
  const isTruncated = ref(false);
  const truncateContainer = ref(null);
  let resizeTimeoutId = null;

  const setIsTruncated = () => {
    if (!truncateContainer.value) return;
    isTruncated.value =
      truncateContainer.value.clientWidth < truncateContainer.value.scrollWidth ||
      truncateContainer.value.clientHeight < truncateContainer.value.scrollHeight;
  };

  const delayedSetIsTruncated = () => {
    clearTimeout(resizeTimeoutId);
    resizeTimeoutId = setTimeout(setIsTruncated, 250);
  };

  onMounted(async () => {
    await nextTick();
    // delay to ensure that the container has been rendered
    delayedSetIsTruncated();
    window.addEventListener('resize', delayedSetIsTruncated);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', delayedSetIsTruncated);
  });

  return { isTruncated, setIsTruncated, delayedSetIsTruncated, truncateContainer };
}
