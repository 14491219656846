<template>
  <div>
    <v-textarea
      v-model="editedCommentDraft"
      :error-messages="ticketCommentErrors"
      auto-grow
      rows="1"
      class="tw-max-h-32 tw-overflow-auto"
      :bg-color="editCommentBackgroundColor"
      @keydown="v$.editedCommentDraft.$touch"
    ></v-textarea>
    <PendingAttachmentsList
      :pending-attachments="attachments"
      class="tw-pt-2"
      @attachment-removed="removeCommentAttachment"
    ></PendingAttachmentsList>
    <div class="tw-flex tw-justify-between">
      <AddAttachmentButton @upload-attachment="uploadAttachment"></AddAttachmentButton>
      <div class="tw-float-right tw-mt-2">
        <SpiceworksButton
          button-type="basic"
          size="small"
          text="Cancel"
          class="tw-mr-2"
          @click="onCancelEdit"
        />
        <SpiceworksButton
          button-type="primary"
          size="small"
          text="Save"
          class="tw-leading-4"
          :disabled="!areCommentChangesPending || uploadingAttachment"
          @click="onClickSave"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf, maxLength } from '@vuelidate/validators';

import { useAttachments } from '@/src/tickets/composables/useAttachments.js';
import AddAttachmentButton from '@/src/common/components/AddAttachmentButton.vue';
import SpiceworksButton from '@/src/common/components/SpiceworksButton.vue';

import { useStore } from 'vuex';
import { capitalize } from 'lodash';

const componentProps = defineProps({
  activityType: {
    type: String,
    required: true,
  },
  ticketId: {
    type: Number,
    required: true,
  },
  entryId: {
    type: Number,
    required: true,
  },
  attachmentsProp: {
    type: Array,
    required: true,
  },
  ticketEntryBody: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  'upload-attachment',
  'cancel-edit',
  'comment-saved',
  'show-ticket-comment-attachment-delete-modal',
]);

const attachments = ref([]);
const { removeAttachment, uploadAttachment, uploadingAttachment } = useAttachments(attachments);
import PendingAttachmentsList from '@/src/common/components/PendingAttachmentsList.vue';

const editedCommentDraft = ref(null);
const attachmentsToRemove = ref([]);

const rules = {
  editedCommentDraft: {
    required: requiredIf(() => attachments.value.length === 0),
    maxLength: maxLength(100000),
  },
};

const v$ = useVuelidate(rules, { editedCommentDraft, attachments });

const store = useStore();
const updateTicketComment = (...args) => store.dispatch('updateTicketComment', ...args);
const showSnackbar = (...args) => store.dispatch('showSnackbar', ...args);
const setIsAnyCommentUnderEdit = (...args) => store.dispatch('setIsAnyCommentUnderEdit', ...args);

onMounted(() => {
  editedCommentDraft.value = componentProps.ticketEntryBody;
  attachments.value = [...componentProps.attachmentsProp];
});

const editCommentBackgroundColor = computed(() => {
  return componentProps.activityType === 'note' ? 'internal-note-bg' : 'comment-bg';
});

const ticketCommentErrors = computed(() => {
  const errors = [];
  if (!v$.value || !v$.value.editedCommentDraft.$dirty) return errors;

  const commentType = componentProps.activityType === 'comment' ? 'response' : 'internal note';
  if (v$.value.editedCommentDraft.required.$invalid) {
    errors.push(`The ${commentType} can't be empty.`);
  } else if (v$.value.editedCommentDraft.maxLength.$invalid) {
    errors.push(`The ${commentType} should be less than 100,000 characters.`);
  }

  return errors;
});

const areCommentChangesPending = computed(() => {
  return (
    (editedCommentDraft.value !== componentProps.ticketEntryBody ||
      JSON.stringify(attachments.value) !== JSON.stringify(componentProps.attachmentsProp)) &&
    !v$.value.editedCommentDraft.$invalid
  );
});

const onCancelEdit = () => {
  emit('cancel-edit');
  attachments.value.length = 0;
  attachmentsToRemove.value.length = 0;
};

const onClickSave = async () => {
  if (attachmentsToRemove.value.length === 0) {
    await saveComment();
    return;
  }
  emit(
    'show-ticket-comment-attachment-delete-modal',
    async () => {
      await saveComment();
    },
    async () => {
      onCancelEdit();
    }
  );
};

const saveComment = async () => {
  if (!v$.value.editedCommentDraft.$invalid) {
    try {
      const response = await updateTicketComment({
        id: componentProps.ticketId,
        ticketCommentParams: {
          id: componentProps.entryId,
          body: editedCommentDraft.value,
          previousBody: componentProps.ticketEntryBody,
          initialUploadIds: Array.from(attachments.value, (attachment) => attachment.signedId),
          attachmentIdsToRemove: attachmentsToRemove.value.map((attachment) => attachment.id),
        },
      });

      setIsAnyCommentUnderEdit(false);
      attachmentsToRemove.value.length = 0;

      emit('comment-saved', {
        newBody: response.comment.body,
        previousBody: response.comment.previousBody,
        editedAt: response.comment.editedAt,
        attachments: response.attachments,
      });

      showSnackbar({
        display: true,
        message: `${capitalize(componentProps.activityType)} edited successfully.`,
        type: 'success',
      });
    } catch (error) {
      showSnackbar({
        display: true,
        message: 'An error occurred while updating the comment. Please try again.',
        type: 'error',
      });
    }
  }
};

const removeCommentAttachment = (index) => {
  if (
    componentProps.attachmentsProp.some(
      (attachment) => attachment.id === attachments.value[index].id
    )
  ) {
    attachmentsToRemove.value.push(attachments.value[index]);
  }
  removeAttachment(index);
};
</script>
