const RefreshGrowlerStore = {
  state: {
    refreshGrowlerProps: {
      display: false,
      message: [],
      onClick: null,
      timeout: 2000,
      isLoading: true,
    },
  },

  mutations: {
    UPDATE_REFRESH_GROWLER(state, refreshGrowlerProps) {
      Object.assign(state.refreshGrowlerProps, refreshGrowlerProps);
    },
  },

  actions: {
    showRefreshGrowler(context, refreshGrowlerProps) {
      context.commit('UPDATE_REFRESH_GROWLER', {
        ...refreshGrowlerProps,
        isLoading: false,
        timeout: 5000,
      });
    },

    /* istanbul ignore next */
    showLoadingRefreshGrowler(context) {
      context.commit('UPDATE_REFRESH_GROWLER', {
        display: true,
        timeout: 2000,
        isLoading: true,
        onClick: null,
      });
    },
  },
};

export default Object.freeze(RefreshGrowlerStore);
