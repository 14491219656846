import { ref } from 'vue';
import UploadsService from '@/src/common/services/uploads-service.js';
import { md5Checksum } from '@/src/util/file-checksum.js';

export function useUploads() {
  const uploadError = ref(false);
  const uploadErrorMessages = ref([]);
  const uploadsService = new UploadsService();

  const uploadSingleAttachment = (event, organizationId) => {
    const file = event.target.files[0];

    if (!file) {
      return [null, null];
    }

    const attachment = {
      filename: file.name,
      byteSize: file.size,
      contentType: file.type,
      organization_id: organizationId,
      uploading: true,
      signedId: null,
    };

    // 200 MB
    const fileSizeLimit = 200000000;
    if (file.size > fileSizeLimit) {
      throw 'File is too big. The size limit is ' + humanizeByteSize(fileSizeLimit) + '.';
    }

    return { attachment, file };
  };

  // signal is an optional object used to cancel the upload request
  const uploadAttachmentFile = async (attachment, file, signal = null) => {
    // Update the checksum (this could take time)
    attachment.checksum = await fileChecksum(file);

    // Create an attachment record and get the signedID
    const blob = await addAttachment(attachment);

    if (uploadError.value || !blob) {
      throw `Error uploading file ${attachment.filename}`;
    }

    // Upload the file to the server
    await uploadFile(blob, file, signal);

    return blob;
  };

  // TODO: This should be expanded to cover all cases. There are some places that still call
  // #fileChecksum, #addAttachment, and #uploadFile separately.
  const uploadFileAndGetBlobSignedId = async (file, attachmentOptions) => {
    try {
      const checksum = await fileChecksum(file);
      const attachment = {
        ...attachmentOptions,
        filename: file.name,
        byteSize: file.size,
        checksum,
        contentType: file.type,
      };
      const uploadBlob = await uploadsService.addAttachment(attachment);
      if (uploadBlob) {
        await uploadFile(uploadBlob, file);
        return uploadBlob.signedId;
      }
    } catch (e) {
      throw `Error uploading file ${file.name}`;
    }
  };

  const addAttachment = async (attachment) => {
    try {
      const response = await uploadsService.addAttachment(attachment);
      return response;
    } catch (e) {
      uploadError.value = true;
      setTimeout(resetErrors, 2000);
      return false;
    }
  };

  // signal is used to cancel the upload request
  const uploadFile = async (blob, file, signal = null) => {
    const { checksum } = blob;
    const headers = {
      'Content-MD5': checksum,
      Accept: '*/*',
    };
    blob.directUpload.headers = { ...blob.directUpload.headers, ...headers };

    try {
      await uploadsService.upload(blob, file, signal);
      return true;
    } catch (e) {
      uploadError.value = true;
      setTimeout(resetErrors, 2000);
      throw e;
    }
  };

  const fileChecksum = async (file) => {
    const checksum = await md5Checksum(file);
    return checksum;
  };

  const resetErrors = () => {
    uploadError.value = false;
    uploadErrorMessages.value = [];
  };

  const humanizeByteSize = (size) => {
    const e = (Math.log(size) / Math.log(1e3)) | 0;
    return (size / Math.pow(1e3, e)).toFixed(2) + ' ' + ('KMGTPEZY'[e - 1] || '') + 'B';
  };

  const truncateFilename = (str, num) => {
    if (str.length <= num) return str;
    else {
      const fileExtension = str.split('.').pop();
      return `${str.slice(0, str.length - fileExtension.length).slice(0, num)}...${fileExtension}`;
    }
  };

  return {
    uploadError,
    uploadErrorMessages,
    uploadSingleAttachment,
    uploadAttachmentFile,
    uploadFileAndGetBlobSignedId,
    addAttachment,
    uploadFile,
    fileChecksum,
    resetErrors,
    humanizeByteSize,
    truncateFilename,
  };
}
