import KnowledgeBaseService from '@/src/knowledge_base/services/knowledge-base-service.js';
import { cloneDeep } from 'lodash';

const knowledgeBaseService = new KnowledgeBaseService();

export default {
  namespaced: true,

  state: {
    isArticleLoading: true,
    isArticleSaveSuccessful: false,
    isArticleSaving: false,
    isReferenceUnderEdit: false,
    isStepUnderEdit: false,
    article: {
      title: '',
      introduction: '',
      conclusion: '',
      isSavingArticle: false,
      articleId: null,
      createdAt: null,
      sharedWith: null,
    },
    references: [],
    unsavedReferences: [],
    steps: [],
    initialStepsIds: [],
    unsavedSteps: [],
    stepsToBeDeleted: [],
  },

  mutations: {
    SET_IS_ARTICLE_LOADING(state, isArticleLoading) {
      state.isArticleLoading = isArticleLoading;
    },

    SET_IS_ARTICLE_SAVE_SUCCESSFUL(state, isArticleSaveSuccessful) {
      state.isArticleSaveSuccessful = isArticleSaveSuccessful;
    },

    SET_IS_ARTICLE_SAVING(state, isArticleSaving) {
      state.isArticleSaving = isArticleSaving;
    },

    SET_IS_STEP_UNDER_EDIT(state, isStepUnderEdit) {
      state.isStepUnderEdit = isStepUnderEdit;
    },

    SET_IS_REFERENCE_UNDER_EDIT(state, isReferenceUnderEdit) {
      state.isReferenceUnderEdit = isReferenceUnderEdit;
    },

    SET_ARTICLE(state, article) {
      state.article = article;
    },

    SET_REFERENCES(state, references) {
      state.references = references;
    },

    SET_UNSAVED_REFERENCES(state, unsavedReferences) {
      state.unsavedReferences = unsavedReferences;
    },

    SET_STEPS(state, steps) {
      state.steps = steps;
    },

    SET_UNSAVED_STEPS(state, unsavedSteps) {
      state.unsavedSteps = unsavedSteps;
    },

    SET_INITIAL_STEPS_IDS(state, ids) {
      state.initialStepsIds = ids;
    },

    UPDATE_STEP(state, { index, updatedStep }) {
      state.steps[index] = updatedStep;
    },

    ADD_STEP_TO_BE_DELETED(state, step) {
      state.stepsToBeDeleted.push(step);
    },

    CLEAR_STEPS_TO_BE_DELETED(state) {
      state.stepsToBeDeleted = [];
    },

    REMOVE_STEP(state, stepId) {
      const index = state.steps.findIndex((step) => step.id === stepId);
      if (index !== -1) {
        state.steps.splice(index, 1);
      }
    },
  },

  actions: {
    setIsArticleLoading(context, isArticleLoading) {
      context.commit('SET_IS_ARTICLE_LOADING', isArticleLoading);
    },

    setIsArticleSaveSuccessful(context, isArticleSaveSuccessful) {
      context.commit('SET_IS_ARTICLE_SAVE_SUCCESSFUL', isArticleSaveSuccessful);
    },

    setIsArticleSaving(context, isArticleSaving) {
      context.commit('SET_IS_ARTICLE_SAVING', isArticleSaving);
    },

    setIsStepUnderEdit(context, isStepUnderEdit) {
      context.commit('SET_IS_STEP_UNDER_EDIT', isStepUnderEdit);
    },

    setIsReferenceUnderEdit(context, isReferenceUnderEdit) {
      context.commit('SET_IS_REFERENCE_UNDER_EDIT', isReferenceUnderEdit);
    },

    setUnsavedSteps(context, unsavedSteps) {
      context.commit('SET_UNSAVED_STEPS', unsavedSteps);
    },

    setInitialStepsIds(context, ids) {
      context.commit('SET_INITIAL_STEPS_IDS', ids);
    },

    resetArticleData(context) {
      context.commit('SET_ARTICLE', {
        title: '',
        introduction: '',
        conclusion: '',
        isSavingArticle: false,
        articleId: null,
        createdAt: null,
        sharedWith: null,
      });
      context.commit('SET_REFERENCES', []);
      context.commit('SET_UNSAVED_REFERENCES', []);
      context.commit('SET_STEPS', []);
      context.commit('SET_UNSAVED_STEPS', []);

      context.commit('SET_IS_STEP_UNDER_EDIT', false);
      context.commit('SET_IS_REFERENCE_UNDER_EDIT', false);
    },

    addUnsavedReference(context, newUnsavedReference) {
      const unsavedReferences = cloneDeep(context.state.unsavedReferences);
      unsavedReferences.push(newUnsavedReference);
      context.commit('SET_UNSAVED_REFERENCES', unsavedReferences);
    },

    deleteUnsavedReference(context, index) {
      if (Number.isInteger(index) && index < context.state.unsavedReferences.length) {
        const unsavedReferences = cloneDeep(context.state.unsavedReferences);
        unsavedReferences.splice(index, 1);
        context.commit('SET_UNSAVED_REFERENCES', unsavedReferences);
      }
    },

    updateUnsavedReference(context, { index: index, unsavedReference: unsavedReference }) {
      if (Number.isInteger(index) && index < context.state.unsavedReferences.length) {
        const unsavedReferences = cloneDeep(context.state.unsavedReferences);
        unsavedReferences[index] = unsavedReference;
        context.commit('SET_UNSAVED_REFERENCES', unsavedReferences);
      }
    },

    async getArticle(context, articleId) {
      try {
        context.commit('SET_IS_ARTICLE_LOADING', true);
        const response = await knowledgeBaseService.getArticle(articleId);

        context.commit('SET_REFERENCES', response.data.knowledgeBaseReferences);
        delete response.data.knowledgeBaseReferences;
        context.commit(
          'SET_STEPS',
          response.data.knowledgeBaseSteps.sort((a, b) => a.position - b.position)
        );
        delete response.data.knowledgeBaseSteps;

        context.commit('SET_ARTICLE', response.data);
      } catch (error) {
        context.dispatch(
          'showSnackbar',
          {
            display: true,
            type: 'error',
            message: 'An error occurred while retrieving the article.',
          },
          { root: true }
        );
        throw error;
      } finally {
        context.commit('SET_IS_ARTICLE_LOADING', false);
      }
    },

    async updateArticle(context, article) {
      const response = await knowledgeBaseService.updateArticle(article.id, {
        title: article.title,
        introduction: article.introduction,
        conclusion: article.conclusion,
        sharedWith: article.sharedWith,
      });
      context.commit('SET_ARTICLE', response.data);
      context.commit('SET_IS_ARTICLE_SAVE_SUCCESSFUL', true);
    },

    updateStep(context, { index, updatedStep }) {
      context.commit('UPDATE_STEP', { index, updatedStep });
    },

    markStepForDeletion({ commit, state }, stepId) {
      const stepToDelete = state.steps.find((step) => step.id === stepId);
      if (stepToDelete) {
        commit('ADD_STEP_TO_BE_DELETED', stepToDelete);
        commit('REMOVE_STEP', stepId);
      }
    },

    async deleteMarkedSteps({ state, commit }) {
      for (const step of state.stepsToBeDeleted) {
        if (step.id) {
          // Only delete from backend if it's an existing step
          await knowledgeBaseService.deleteAttachment(step.id);
          await knowledgeBaseService.deleteStep(step.id);
        }
      }
      commit('CLEAR_STEPS_TO_BE_DELETED');
    },

    async createKnowledgeBaseModels(context, article) {
      const response = await knowledgeBaseService.createArticle({
        title: article.title,
        introduction: article.introduction,
        conclusion: article.conclusion,
        shared_with: 0,
      });

      const articleId = response.data.id;

      await Promise.all(
        context.state.unsavedReferences.map((reference) =>
          knowledgeBaseService.createReference(articleId, reference)
        )
      );

      await Promise.all(
        context.state.unsavedSteps.map((step, index) => {
          step['position'] = index + 1;
          return knowledgeBaseService.createStep(articleId, step);
        })
      );

      context.commit('SET_ARTICLE', response.data);
      context.commit('SET_IS_ARTICLE_SAVE_SUCCESSFUL', true);

      return articleId;
    },

    async updateKnowledgeBaseModels(context, article) {
      await Promise.all(
        context.state.unsavedReferences.map((reference) =>
          knowledgeBaseService.createReference(article.id, reference)
        )
      );

      await context.dispatch('deleteMarkedSteps');

      await Promise.all(
        context.state.unsavedSteps.map((step, index) => {
          step['position'] = context.state.steps.length + index + 1;
          return knowledgeBaseService.createStep(article.id, step);
        })
      );

      await Promise.all(
        context.state.steps.map((step) => knowledgeBaseService.updateStep(step.id, step))
      );

      context.dispatch('updateArticle', article);
    },
  },
};
