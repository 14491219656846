<template>
  <div
    class="expandable-image"
    :class="{ expanded }"
    @click.stop="expand()"
  >
    <!-- Thumbnail -->
    <v-img
      ref="thumbnail"
      v-bind="$attrs"
      :height="imageHeight"
      :width="imageWidth"
      :max-height="maxHeight"
      :max-width="maxWidth"
      @load="setImageSize"
    >
      <template #placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular indeterminate></v-progress-circular>
        </v-row>
      </template>
      <template #error>
        <v-icon class="tw-h-full tw-w-full">mdi-image-broken</v-icon>
      </template>
    </v-img>

    <!-- Expanded -->
    <InfoModal
      v-model:is-showing-dialog="expanded"
      :show-action-buttons="true"
      :show-close-button="true"
      max-width="none"
      content-class="tw-h-full"
      @close-dialog="expanded = false"
    >
      <slot></slot>
      <template #content>
        <div class="tw-flex tw-h-full">
          <img
            v-show="imageLoaded"
            :src="$attrs.src"
            class="tw-m-auto tw-max-h-full"
            @load="() => (imageLoaded = true)"
          />
          <v-row
            v-show="!imageLoaded"
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular indeterminate></v-progress-circular>
          </v-row>
        </div>
      </template>
      <template #buttonActions>
        <SpiceworksButton
          button-type="primary"
          icon="mdi-download"
          text="Download"
          :href="$attrs.src"
        />
      </template>
    </InfoModal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import InfoModal from '@/src/common/components/InfoModal.vue';
import SpiceworksButton from '@/src/common/components/SpiceworksButton.vue';

const props = defineProps({
  maxHeight: {
    type: String,
    default: '200px',
  },
  maxWidth: {
    type: String,
    default: '200px',
  },
});

const emit = defineEmits(['expand']);

const expanded = ref(false);
const imageLoaded = ref(false);

const imageHeight = ref(props.maxHeight.value);
const imageWidth = ref(props.maxWidth.value);

const thumbnail = ref(null);

const expand = () => {
  expanded.value = true;
  emit('expand');
};

const setImageSize = () => {
  const img = thumbnail.value.$el.querySelector('img');
  if (img) {
    imageHeight.value = img.naturalHeight;
    imageWidth.value = img.naturalWidth;
  }
};
</script>

<style lang="scss">
.expand-button {
  path {
    fill: #434648;
  }
}

.expandable-image {
  cursor: zoom-in;
}

.expandable-modal-buttons {
  padding: 0 0.5rem !important;
  @apply tw-mr-1 tw-px-1 tw-font-bold tw-normal-case;

  img {
    filter: invert(1);
  }
}
</style>
